
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface LojaPlano {
  codConcessionaria: number | null;
  dataFim: string[];
  dataInicio: string[];
  id: number | null;
  idPlano: number | null;
  nome: string;
  nomeFantasia: string;
  status: string;
  limiteMensalBuscaPlaca: number | null;
  saldoBuscaPlaca: number | null;
}

export default defineComponent({
  name: "FilterPlanos",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const store = useStore();
    const emitter = useEmitter();
    const closeModal = ref(false);

    const lojasSelect: Ref<LojaPlano[]> = ref([]);
    const planosSelect: Ref<any> = ref([]);
    const paramsFilter: Ref<LojaPlano> = ref({
        codConcessionaria: null,
        dataFim: [],
        dataInicio: [],
        id: null,
        idPlano: null,
        nome: '',
        nomeFantasia: '',
        status: '',
        limiteMensalBuscaPlaca: null,
        saldoBuscaPlaca: null
    })

    async function getAll() {
        try {
            const { data } = await Api.get("getAllPlans");
            lojasSelect.value = data;
        } catch (error) {
        console.log(error);
      }
    }

    async function getAllPlanosLoja() {
        try {
            const { data } = await Api.get("getAllPlanosLoja");
            planosSelect.value = data;
        } catch (error) {
        console.log(error);
      }
    }

    async function enviarEmit() {
      await emitter.emit("paramsFilter", {
        paramsFilter: paramsFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    onMounted(() => {
      getAll();
      getAllPlanosLoja();
    });

    // watch(() => paramsFilter.value, () => {console.log('aqui', paramsFilter.value)}, {deep:true}) 

    return { lojasSelect, enviarEmit, ElConfigProvider, ptBr,paramsFilter, planosSelect, closeModal };
  },
});
